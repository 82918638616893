<template>
  <v-container grid-list-xs  xs="12" 
  lg="6"
  sm="5" 
  md="4">
    <v-app-bar color="white" flat>
      <v-btn
        icon
        @click="toProducts"
        large
        v-if="$route.params.page !== 'products' && $route.params.child !== 'i'"
        
      >
        <v-icon color="black">mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{
          $route.params.child == "i"
            ? ""
            : $route.params.page == "search"
            ? "Search"
            :$route.params.page == "faq"
            ? "FAQ"
            : ""
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--Template burger-->
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            icon
            v-bind="attrs"
            v-on="on"
            v-if="$route.params.child !== 'i'"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list width="150">
          <v-list-item
            v-for="(menu, i) in menusDots"
            :key="i"
            @click="menu.goTo(i)"
            dense
          >
            <v-list-item-title
              :class="{ selected: activeTabIndex.includes(i) }"
            >
              {{ menu.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- end of burger -->
    </v-app-bar> 
    <div v-if="$route.params.child == 'i'">
      <component :is="navigation[4].component" />
    </div>
    <div v-else>
      <div v-for="(value, index) in navigation" :key="index" class="w-100">
        <div v-if="Route === value.title">
          <component :is="value.component" />
        </div>
      </div>    
    </div>

<v-bottom-navigation 
class="bottomnavbar">
<div class="nav-div d-flex justify-between align-center">
  <v-main  v-for="(value, index) in menus" :key="index">
  <v-btn @click="value.goTo(index)" :ripple="false" icon color="leaf" id="no-background-hover">

    <span>{{ value.title }}</span>

    <v-icon class="i-con" size="32px"   :color="$route.params.page == value.route ?'leaf': 'grey' ">{{value.icon}}
    </v-icon>
    
  </v-btn>
</v-main>
    <v-btn
      elevation="0"
      @click="toCart"
      :ripple="false" icon color="gray" id="no-background-hover"
    >

      <v-badge  bordered overlap color="leaf">
        
        <template v-slot:badge >
          <div>{{cart.length}}</div>
        </template>

    <v-icon  color="grey" size="32px" >
          mdi-cart-variant
    </v-icon>
    <div>Cart</div>
      </v-badge>
    </v-btn>
</div>


  </v-bottom-navigation>
  </v-container>  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",

  data() {
   
    return {
     
      Route: this.$route.params.page,
      navigation: [
        { title: "products", component: () => import("./Products") },
        { title: "product", component: () => import("./Product") },
        { title: "cart", component: () => import("./Cart") },
        { title: "users", component: () => import("./Users") },
        { title: "i", component: () => import("./Invoice") },
        { title: "search", component: () => import("./Search") },
        { title: "history", component: () => import("./History") },
        { title: "faq", component: () => import("./FAQ") },
      ],

      menus: [
         {
            title: 'Home',
            route: 'products',
            icon: 'mdi-home',
            goTo: (i) => this.toProducts(i),
         },
         {
            title: 'User',
            route: 'users',
            icon:'mdi-account-circle',
            goTo: (i) => this.toUsers(i),
         }, 
         {
            title: 'History',
            route: 'history',
            icon: 'mdi-clipboard-text-clock',
            goTo: (i) => this.toHistory(i),
         },
             
      ],

      menusDots: [
         {
            title: 'Search',
            route: 'search',
            goTo: (i) => this.toSearch(i),
         },
         {
            title: 'FAQ',
            route: 'faq',
            goTo: (i) => this.toFAQ(i),
         }, 
        
        
      ],
      activeTabIndex: [],
    };
  },

  //Template burger

  //
  

  watch: {
    $route: "updateRoute",
  },

  computed: {
   
    ...mapGetters({
      franchise: "getFranchise",
      cart:'getCart'
    }),
    webShareApiSupported() {
      return navigator.share;
    },
  },

  methods: {
    mounted () {
      console.log(this.$vuetify.breakpoint.width)
    },
    change(i, allow) {
      this.isLoading = true;
      if (!allow && this.cart.length !== 0) {
        this.isLoading = false;
        this.dialog = true;
        this.selectedOutlet = i;
      } else {
        this.dialog = false;
        this.$store.dispatch("updateCart", []);
        this.$store.dispatch("updateSO", i);
        this.out = i;
        let a = this.franchise.outlets.findIndex((d) => d.name == i);
        this.$store.dispatch("changeOutlet", a).then(() => {
          this.isLoading = false;
        });
      }
    },
    updateRoute() {
      this.Route = this.$route.params.page;
    },

    changeLights() {
      this.lights = this.light ? "mdi-brightness-5" : "mdi-brightness-2";
      this.light = !this.light;
    },

    changeMode() {
      this.$store.dispatch("switchDark");
    },

    toProducts(i) {
    

      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
      this.activeTabIndex = [];
      this.activeTabIndex.push(i);
    },

    toUsers(i) {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "users" },
      });
      this.activeTabIndex = [];
      this.activeTabIndex.push(i);
    },

    toSearch(i) {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "search" },
      });
      this.activeTabIndex = [];
      this.activeTabIndex.push(i);
    },
    toHistory(i) {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "history" },
      });
      this.activeTabIndex = [];
      this.activeTabIndex.push(i);
    },
    toCart(i) {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "cart" },
      });
      this.activeTabIndex = []
      this.activeTabIndex.push(i)
    },
    webShare() {
      if (this.webShareApiSupported) {
        navigator.share({
          title: `${this.franchise.name} @ Getorders`,
          text: `Come buy at ${this.franchise.name}`,
          url: `https://v2.getorders.app/${this.$route.params.child}`,
        });
      } else {
        alert("Share function is not supported for this application");
      }
    },
    toFAQ(i) {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "faq" },
      });
      this.activeTabIndex = [];
      this.activeTabIndex.push(i);
    },
  },

  beforeMount() {

    if (!this.$route.params.child) {
      window.location.href = "https://www.getorders.app/";
    } else if (!this.$route.params.page) {
      this.$router.push({
        name: "Home",
        params: { child: this.$route.params.child },
      });
    }
  },

  // mounted() {
  //   if (this.franchise.faq) {
  //     this.menus.push({
  //       title: "FAQ",
  //       goTo: () => this.toFAQ(),
  //     });
  //   }
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#no-background-hover::before {
 background-color: transparent !important;
}
.bottomnavbar{
 
  position: fixed;
  bottom: 0;
}
.stylecart{
  margin-top: -30px;
  border-radius: 500px ;
  size: 35px;

}
.icon{
  height: 100px;
  width: 200px;
}
h3 {
  margin: 40px 0 0;
}
.logo {
  .icon-align {
    width: 19%;
  }
}
.distort {
  display: none;
}
.height {
  height: 90%;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.selected {
  color: #42b983;
}
.hello {
  display: flex;
  justify-content: center;
}


@media (min-width: 601px){ 
    .nav-div{
      width: 60%;
    }
  }


@media (max-width: 600px){
  .distort {
    display: block;
  }
  .paicon{
   margin-left: 0;
   margin-right: 0;
  }
  .nav-div{
    width: 100%;
  }

  @media (max-width: 300px){
  .distort {
    display: block;
  }
  .paicon{
   margin-left: 0;
   margin-right: 0;
  }
  .nav-div{
    width: 120%;
  }
  }


}
</style>


